<!-- 我的钱包容器 -->
<script setup>
import { onMounted, computed } from 'vue';
import AccountComp from '../Account/index.vue';
import AvatarComp from '../Account/components/Avatar.vue';
import PartnersComp from '../Partners/index.vue';
import NFTListComp from './NFTList.vue';
import $router from '@/router';
import { useAppStore } from '@store/appStore';
import useAccountStore from '@store/accountStore.js';
import { curNavType } from '../useCardPackage.js';

const appStore = useAppStore();
const accStore = useAccountStore();
</script>

<template>
  <div class="container-wrap">
    <section class="account-container" v-show="curNavType === 'avatar'">
      <header v-show="appStore.curDevice == 'pc'">{{ $t('detail.25') }}</header>
      <AvatarComp />
    </section>

    <section class="account-container" v-show="curNavType === 'account'">
      <AccountComp />
    </section>

    <section class="nft-container" v-show="curNavType === 'nft'">
      <NFTListComp />
    </section>

    <section class="partners-container" v-show="curNavType === 'affinity'">
      <PartnersComp />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.container-wrap {
  min-height: calc(100vh - $pcTopBarHeight);
  color: #fff;
  @include -width(100%, 100%, 12.8rem);
  height: 100%;
  @include -padding-top(0.2rem, 0.64rem, 0.64rem);
  @include -padding-left(0.3rem, 0.5rem, 0.5rem);
  @include -padding-right(0.3rem, 0.5rem, 0);
  // @include -media($pc, display, flex);
}

.account-container {
  header {
    font-size: 0.5rem;
    margin-bottom: 0.26rem;
  }

  .nft-list {
    display: flex;
    flex-wrap: wrap;

    .nft-card-item-wrap {
      margin: 0.3rem 0.36rem 0 0;
    }
  }
}
</style>
