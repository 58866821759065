<!-- 卡包页面 -->
<script setup>
import { ref, onMounted, watchEffect } from 'vue';
import NavPanel from './components/NavPanel';
import Container from './components/Container';
import $router from '@/router';
import { useAppStore } from '@store/appStore';
import useAccountStore from '@store/accountStore.js';
import { showPanel, setShowNavPanel, curNavType, pickSideNav } from './useCardPackage.js';

const appStore = useAppStore();
const accStore = useAccountStore();

watchEffect(async () => {
  pickSideNav(curNavType.value);
  // await accStore.getProfileInfo();
});

const imgLoad = ref(false);
const imgRef = ref(false);
//大图没加载完之前 用小图代替
onMounted(() => {
  imgRef.value.onload = function () {
    imgLoad.value = true;
  };
});
</script>

<template>
  <div :class="['nft-market-wrap', { mob: appStore.curDevice !== 'pc' }]">
    <div class="before"></div>
    <!-- 移动端的侧边栏，滑动弹出面板 -->
    <transition name="market-panel">
      <div class="panel-container" v-show="appStore.curDevice !== 'pc' && showPanel">
        <NavPanel />
      </div>
    </transition>

    <transition name="market-main">
      <section class="main" v-if="appStore.curDevice !== 'pc' && !showPanel">
        <!-- 容器 -->
        <section class="container-section">
          <Container />
        </section>
      </section>
    </transition>

    <!-- 内容区 -->
    <section class="main" v-if="appStore.curDevice === 'pc'">
      <!-- 侧边选择面板 -->
      <aside>
        <NavPanel />
      </aside>

      <!-- 商品列表 -->
      <section class="container-section">
        <Container />
      </section>
    </section>
    <!-- 优化首次进来container黑色 -->
    <div class="after" :style="{ backgroundColor: imgLoad ? '#000' : 'rgba(0, 0, 0, 0.7)' }">
      <img
        v-if="!imgLoad"
        src="https://game.legendranch.app/legendaryranch/slideShow/bg-home-load.png"
      />
      <img
        v-show="imgLoad"
        ref="imgRef"
        src="https://game.legendranch.app/legendaryranch/slideShow/bg-home.webp"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel-container {
  position: fixed;
  @include -media($phone, position, relative);
  @include -media($phone, top, 0);
  top: $mobTopBarHeight;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;

  .btns {
    margin-bottom: 0 !important;

    button {
      width: 100% !important;
      position: absolute;
      left: 0.6rem;
    }
  }
}

.nft-market-wrap {
  position: relative;
  @include -height(100%, 100vh, 100%);
  $navHeight: 1.5rem; // tab导航栏高度
  $asideWidth: 3.64rem; // 侧边栏宽度

  .before {
    position: absolute;
    width: 101%;
    height: 101%;
    left: -1%;
    top: -1%;
    opacity: 1;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .after {
    position: absolute;
    width: 101%;
    height: 101%;
    left: -1%;
    top: -1%;
    z-index: -2;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    // background-size: cover;
    // background-position: center center;
  }

  &.mob {
    border-bottom: solid 0.01px transparent;
    // height: calc(100vh - $mobTopBarHeight);
  }

  color: #fff;

  > .main {
    display: flex;
    height: 100%;

    > aside {
      flex: 0 0 $asideWidth;
      border-right: solid 1px #3f354f;
    }

    > .container-section {
      flex: auto;
      overflow: auto;
    }

    @media screen and (max-width: 1440px) {
      > .container-section {
        padding-left: 0;

        :deep(.sale-num) {
          font-size: 0.3rem;
        }
      }
    }
  }

  // 呼出面板按钮
  .btns {
    @include flexPos(center);
    padding: 0 0.48rem;
    position: relative;

    button {
      width: 4.96rem !important;
      height: 0.7rem;
      background-color: #5b3bb7;
      border-radius: 0.06rem;
      font-size: 0.28rem;
      position: absolute;
      bottom: -2.5rem;
    }

    // margin-bottom: 4.7rem;
  }
}
</style>
