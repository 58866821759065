<!-- 伙伴列表 -->
<script setup>
import { useAppStore } from '@store/appStore';
import useAccountStore from '@store/accountStore.js';
import { setShowNavPanel } from '../useCardPackage.js';
const appStore = useAppStore();
const accStore = useAccountStore();
const list = { 1: 'detail.51', 2: 'detail.50', 3: 'detail.53', 4: 'detail.52' };
const icon = { 1: 'icon-a-3svg', 2: 'icon-a-1', 3: 'icon-a-5', 4: 'icon-a-3svg' };
</script>

<template>
  <div class="partners-wrap van-hairline--top">
    <div class="product-item" v-for="(item, index) in accStore.relationship" :key="index">
      <!-- 'https://game.legendranch.app/legendaryranch/picture/profile/0.png' -->
      <div class="avart" v-lazy:background-image="!item.avatar ? require('../../../assets/img/home/logo.png') : item.avatar"></div>
      <div class="info-wrap">
        <div class="bolck">
          <!-- <i :class="['iconfont', item.gender == 1 ? 'icon-nan' : 'icon-nv']"></i> -->
          <span class="name">{{ item.name }}</span>
        </div>
        <div class="btn-wrap">
          <i :class="['iconfont', icon[item.types]]"></i>
          <span class="name">{{ $t(list[item.types]) }}</span>
        </div>
      </div>
    </div>
  </div>
  <van-empty
    class="empty"
    :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
    :description="$t('panel.34')"
    v-show="!accStore.relationship"
  />
  <!-- 按钮 -->
  <footer>
    <div class="back-btn" v-show="appStore.curDevice !== 'pc'" @click="setShowNavPanel(true)">
      {{ $t('dialog.3') }}
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.partners-wrap {
  width: 100%;
  @include flexPos(space-between, center);
  flex-wrap: wrap;
  overflow: auto;
  padding-top: 0.2rem;

  .product-item {
    @include -width(50%,2.86rem,2.86rem);
    height: 1.1rem;
    margin: 0.2rem 0;
    cursor: pointer;
    @include flexPos(space-around, center);
    color: white;
    background: linear-gradient(to top, rgba(255, 255, 255, 0), #090030);
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
  }
}

.avart {
  @include flexPos(center);
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  background-size: cover;
  background-position: center center;
  border: 2px solid #ffffff;
}

.info-wrap {
  @include flexPos(flex-start, center);
  flex-direction: column;
  @include -width(auto,1.3rem,1.33rem);

  .bolck {
    @include flexPos(space-around, center);
    font-size: 0.26rem;
    .name {
      margin-left: 0.1rem;
    }
  }

  .btn-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    @include -width(2rem,1.3rem,1.33rem);
    
    @include -height(0.3rem,0.3rem,0.3rem);
    margin-top: 0.14rem;
    
    background-color: #3f2e89;
    border-radius: 32px;

    .name {
      text-align: center;
      font-size: 0.12rem;
      margin-left: 0.1rem;
    }
  }
}
footer {
  width: 100%;
  @include flexPos(space-around, center);
  margin: 0.5rem 0;
  .back-btn {
    width: 6.7rem;
    height: 0.7rem;
    @include flexPos(center);
    font-size: 0.28rem;
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 0.06rem;
  }
}
.empty {
  height: 80vh;
  width: 100%;
  // margin: 0.5rem;
  @include flexPos(center);
  flex-direction: column;
}
:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  text-align: center;
  // margin-left: 45%;
  @include flexPos(center);
  flex-direction: column;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}
</style>
