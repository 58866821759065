<!-- NFT列表 -->
<script setup>
import { ref, reactive, computed, watch, provide, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import useMarketStore from '@store/cattleMarketStore.js';
import useCattleStore from '@contractStore/cattleStore.js';
import useCattleBadgeStore from '@contractStore/cattleBadgeStore.js';
import useBlindBoxStore from '@contractStore/blindBoxStore.js';
import usePlanetStore from '@contractStore/planetStore.js';
import usePlanetLeaseStore from '@contractStore/planetLeaseStore.js';
import { setShowNavPanel, curNavType, curCardType } from '../useCardPackage.js';
import useOATMintStore from '@contractStore/oatMintStore.js';
import i18n from '@/utils/i18n.js';
import { padNumber } from '@/utils/tools.js';

const $t = i18n.global.t;
const appStore = useAppStore();
const packageStore = useMarketStore();
const cattleStore = useCattleStore();
const cattleBadgeStore = useCattleBadgeStore();
const planetStore = usePlanetStore();
const blindBoxStore = useBlindBoxStore();
const oatMintStore = useOATMintStore();
const planetLeaseStore = usePlanetLeaseStore();

const isCattle = computed(() => {
  return curCardType.value === 'hero' || curCardType.value === 'cattle';
});

// 需要展示的卡牌列表
const cardList = computed(() => {
  // 创世牛牛
  if (curCardType.value === 'hero') {
    console.log('Myhero...');
    return packageStore.heroList;
  }
  // 普通牛牛
  if (curCardType.value === 'cattle') {
    console.log('Mycattle...');
    return packageStore.cattleList;
  }
  // 星球
  if (curCardType.value === 'planet') {
    console.log('Myplanet...', planetStore.planetList);
    return planetStore.planetList;
  }
  // 星球租賃
  if (curCardType.value === 'lease') {
    console.log('Myplanet lease...', planetLeaseStore.leaseList);
    return planetLeaseStore.leaseList;
  }
  // 盲盒
  if (curCardType.value === 'blindBox') {
    console.log(blindBoxStore.blindBoxList);
    return blindBoxStore.blindBoxList;
  }
  // 牛牛徽章
  if (curCardType.value === 'cattleBadge') {
    // console.log(blindBoxStore.blindBoxList);
    return cattleBadgeStore.cattleBadgeList;
  }
  // 兑换券
  if (curCardType.value === 'others') {
    return [...oatMintStore.oatList, ...blindBoxStore.blindBoxList];
    // return [];
  }

  return [];
});

/**
 * 手動分頁
 */
const currentPage = ref(1);
async function getCardList(val) {
  let tokenids = [];
  try {
    if (curCardType.value === 'hero') {
      // 创世牛牛
      tokenids = await cattleStore.getCattleIds(appStore.defaultAccount);
      await packageStore.fetchCattleArray(
        curCardType.value,
        cattleStore.tokenIds.slice((currentPage.value - 1) * 20, currentPage.value * 20)
      );
      console.log('My hero list...', tokenids, packageStore.heroList);
      packageStore.totalNum = tokenids.length;
    } else if (curCardType.value === 'cattle') {
      // 普通牛牛
      // console.log('cat..',val, currentPage.value, cattleStore.tokenIds, cattleStore.tokenIds.slice((val - 1) * 20, val * 20));
      tokenids = await cattleStore.getCattleIds(appStore.defaultAccount, false);
      await packageStore.fetchCattleArray(
        curCardType.value,
        cattleStore.tokenIds.slice((currentPage.value - 1) * 20, currentPage.value * 20)
      );
      console.log('My cattle list...', tokenids, packageStore.cattleList);
      packageStore.totalNum = tokenids.length;
    } else if (curCardType.value === 'planet') {
      // 星球
      await planetStore.getPlanetList(appStore.defaultAccount);
    } else if (curCardType.value === 'blindBox') {
      // 盲盒
      await blindBoxStore.getAllBoxList(appStore.defaultAccount);
      packageStore.totalNum = blindBoxStore.blindBoxList.length;
      blindBoxStore.setBlindBoxList(
        blindBoxStore.blindBoxList.slice((currentPage.value - 1) * 20, currentPage.value * 20)
      );
      console.log('total..', packageStore.totalNum);
    } else if (curCardType.value === 'others') {
      // oat 徽章
      // await oatMintStore.getUserOATList();
      // 二開盲盒
      await blindBoxStore.getOtherVoucherList(appStore.defaultAccount);
      packageStore.totalNum = blindBoxStore.blindBoxList.length;
      blindBoxStore.setBlindBoxList(
        blindBoxStore.blindBoxList.slice((currentPage.value - 1) * 20, currentPage.value * 20)
      );

      console.log('total..', packageStore.totalNum, blindBoxStore.blindBoxList);
    }
  } catch (error) {}
}

// 是否是卡牌
function isCard(type) {
  if (['Bronze','Silver','Gold','Platinum','Diamond','Master'].includes(type)) {
    return `badge-img ${type}`;
  } 
  if (type === 'Halo-Box') {
    return 'halo-box-img';
  } else if (
    type === 'Mystery-Box' ||
    type === 'Skins-Pack' ||
    type === 'Items-Pack' ||
    type === 'Bovine-Hero' ||
    type === 'Cattle'
  ) {
    return 'card-img';
  } else {
    return 'cattle-img';
  }
}

// 获取卡牌名称
function getCardName(item) {
  if (curCardType.value == 'hero') {
    return `${$t('card.3')} #${item.id}`;
  } else if (curCardType.value == 'cattle') {
    return `${$t('card.4')} #${item.id}`;
  }else if (curCardType.value == 'cattleBadge') {
    return `${$t(item.name)}*${item.length}`;
  }  else  {
    return $t(item.name);
  }
}

/**
 * 路由跳转详情
 * @param {Number} id 卡牌ID
 * @param {String} cate 类型：盲盒、牛牛、星球
 * @param {String} type 具体类型
 */
const router = useRouter();
async function toDetail(id, type = 'cattle') {
  console.log('card..', id, type);
  router.push({
    name: 'cardPackageDetail',
    query: {
      id,
      cate: curCardType.value, // 对应 NFT 卡牌类型分类 planet hero cattle blindBox lease others
      type: type === '' ? 'cattle' : type // 对应 卡牌类型下面的分类 如 blindBox 下级： igoBlindBox myBlindBox
    }
  });
}
</script>

<template>
  <div class="NFT-wrap">
    <!-- 图片 -->
    <div
      :class="['product-wrap', appStore.curDevice == 'pc' ? 'van-hairline--top' : '']"
      v-if="cardList?.length"
    >
      <div class="product-item" v-for="(item, index) in cardList" :key="index">
        <!-- 图片 -->
        <div :class="['img-wrap']" @click="toDetail(item.id, item.cardType)">
          <div v-if="item?.cardKey === 'cattleBadge'" 
          :class="`badge-img ${item.cardType}`" 
          @click="toDetail(item.id, item.cardType)"></div>
          <img
            v-else
            :src="item.image"
            alt=""
            :class="isCard(item.cardType)"
          />
        </div>
        <!-- 名称 -->
        <span class="id">{{ getCardName(item) }}</span>
      </div>
    </div>
    <!-- <img src="@img/card/halo-box.png" alt="" v-else> -->
    <van-empty
      v-else
      class="empty"
      :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
      :description="$t('msg.19')"
    />
    <!-- 分页器 -->
    <div class="pagtination-wrap" v-show="packageStore.totalNum">
      <el-pagination
        v-model:current-page="currentPage"
        background
        layout="prev, pager, next"
        @current-change="getCardList(currentPage)"
        :total="packageStore.totalNum"
        :pager-count="5"
        :page-size="20"
      >
      </el-pagination>

    </div>
    <!-- 按钮 -->
    <footer>
      <div class="btn" v-show="false">more</div>
      <div class="back-btn" v-show="appStore.curDevice !== 'pc'" @click="setShowNavPanel(true)">
        {{ $t('dialog.3') }}
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.NFT-wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.product-wrap {
  display: grid;
  grid-template-columns: repeat(auto-fit, 2.4rem);
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 0.44rem;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  @include -padding-top(0.2rem, 0.4rem, 0.4rem);
  @include -media($phone, grid-gap, 0.34rem);
  @include -media($phone, padding-left, 0.2rem);
  @include -media($phone, grid-template-columns, repeat(auto-fit, 3rem));

  .product-item {
    @include flexPos(center);
    flex-direction: column;

    .img-wrap {
      cursor: pointer;
      @include flexPos(center);
      @include -width(auto, 2.4rem, 2.4rem);
      @include -height(auto, 2.4rem, 2.4rem);
      padding: 0.2rem 0.3rem 0.3rem;
      background: linear-gradient(to top, #402866, rgba($color: #3f00a7, $alpha: 0));
      border: 1px solid rgba($color: #fff, $alpha: 0.5);
      border-radius: 10px;

      .card-img {
        @include flexPos(center);
        @include -width(2.4rem, 1.5rem, 1.5rem);
        // @include -height(3.5rem, 1.9rem, 1.9rem);
        cursor: pointer;
      }

      .cattle-img {
        @include flexPos(center);
        @include -width(2.4rem, 1.9rem, 1.9rem);
        // @include -height(2.4rem, 1.9rem, 1.9rem);
        cursor: pointer;
      }

      .halo-box-img {
        @include flexPos(center);
        @include -width(2.8rem, 1.9rem, 1.9rem);
        @include -height(1.3rem, 1.3rem, 1.3rem);
        cursor: pointer;
      }
      .badge-img {
        --badge-width: 106;
        width: 106px;
        height: 106px;
       background: url('../../../assets/img/common/icon.png') no-repeat;
       &.Bronze{background-position: 0 -106px * 6;}
       &.Silver{background-position: -106px*2 -106px * 3;}
       &.Gold{background-position: -106px -106px * 3;}
       &.Platinum{background-position: -106px*2 -106px * 1;}
       &.Diamond{background-position: -106px*3 -106px * 3;}
       &.Master{background-position: -106px*1 -106px * 7;}
      }
    }

    .id {
      color: white;
      text-align: center;
      @include -font-size(0.26rem, 0.26rem, 0.24rem);
      line-height: 0.28rem;
      @include -margin-top(0.15rem, 0.15rem, 0.1rem);
    }
  }
}

.empty {
  width: 100%;
  height: 80vh;
  @include flexPos(center);
  flex-direction: column;
}

:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  // margin-left: 45%;
  @include flexPos(center);
  flex-direction: column;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}

footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  @include -media($pc, display, none);
  background-color: rgba($color: #070a21, $alpha: 1);
  @include flexPos(space-around, center);
  height: 1.77rem;
  .btn {
    @include flexPos(center);
    @include -width(1.22rem, 1.22rem, 1.5rem);
    @include -height(0.35rem, 0.35rem, 0.5rem);
    @include -font-size(0.14rem, 0.18rem, 0.18rem);
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 3px;
  }
  .back-btn {
    width: 6.7rem;
    height: 0.7rem;
    @include flexPos(center);
    font-size: 0.28rem;
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 6px;
  }
}

// 分页器
.pagtination-wrap {
  @include flexPos(center);
  margin: 0.5rem;
  @include -media($phone, padding-bottom, 1.8rem);
  @include -media($pad, padding-bottom, 1.8rem);

  :deep(.el-input__inner) {
    background-color: black;
    color: white;
    // border: 1px solid #656565;
  }

  :deep(button) {
    background-color: transparent !important;
  }

  :deep(.el-pager) {
    li {
      background-color: transparent !important;
    }
  }

  :deep(.el-pagination.is-background .el-pager li) {
    background-color: black;
    color: white;
    @include -font-size(0.4rem, 0.4rem, 0.2rem);
    opacity: 0.2;
    // border: 1px solid #656565;

    &:not(.disable).active {
      background-color: transparent;
      font-weight: bold;
      opacity: 1;
    }
  }

  :deep(.el-pagination.is-background .btn-next) {
    background-color: black;
    color: white;
    // border: 1px solid #656565;
  }

  :deep(.el-pagination.is-background .btn-prev) {
    background-color: black;
    color: white;
    // border: 1px solid #656565;
  }

  :deep(.el-icon) {
    @include -font-size(0.4rem, 0.4rem, 0.2rem);
    font-weight: bold !important;
  }
}
</style>
