<!-- NFT列表 -->
<script setup>
import { ref, reactive, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useAppStore } from '@store/appStore';
import useAccountStore from '@store/accountStore';
import { setCurNavType, setShowNavPanel } from '../../useCardPackage.js';
import $load from '@cps/GlobalLoading';
import { ElMessage } from 'element-plus';
import commonObj from '@/contracts/commonContract.js';
import i18n from '@/utils/i18n.js';
const $t = i18n.global.t;

const appStore = useAppStore();
const accStore = useAccountStore();
const pickId = ref('');

(async () => {
  $load({ isShow: true });
  try {
    const { contract } = new commonObj();
    // console.log('contract',contract,appStore.defaultAccount)
    const list = await contract.getUserProfilePhoto(appStore.defaultAccount);
    // console.log('avatar list..', list);
    if (list?.length) {
      await accStore.getAvatarList(list);
    }
  } catch (error) {
    console.log(error);
  }
  $load({ isShow: false });
})();

/**
 * 选择并提交修改
 */
async function hanldeConfirm() {
  if (pickId.value == '') {
    return;
  }
  $load({ isShow: true });
  // 鉴权校验，获取 token
  try {
    const token = await accStore.verify();
    if(!token) {
       $load({ isShow: false });
      return
    }
    const aid = await accStore.editUserAvatar(pickId.value, token);
    await accStore.getProfileInfo();
    ElMessage({
      message: $t('msg.11'),
      type: 'success'
    });
    pickId.value = '';
  } catch (error) {
    console.log(error);
    ElMessage({
      message: $t('msg.12'),
      type: 'error'
    });
  }

  $load({ isShow: false });
}

const checkList = computed(() => {
  // console.log(' accStore.avartList...',  accStore.avartList);
  if(accStore.avartList?.length > 0){
    return accStore.avartList.filter(item => item.check);
  }else {
    return []
  }
  // return accStore.avartList?.length && accStore.avartList.filter(item => item.check);
});
function handleClick(item, index) {
  for (let i = 0; i < accStore.avartList?.length; i++) {
    if (item.avatar == accStore.avartList[i]?.avatar) {
      accStore.avartList[i].check = true;
      pickId.value = item.avatar;
    } else {
      accStore.avartList[i].check = false;
    }
  }
}

function hanldeCancel() {
  pickId.value = '';
  if (appStore.curDevice == 'phone') {
    setShowNavPanel(true);
  } else {
    setCurNavType('account');
  }
}

/**
 * 默认头像 or 用户头像
 */
const avatar = computed(() => {
  return !checkList?.value[0]?.uri ? accStore.avatar : checkList.value[0].uri;
});
</script>

<template>
  <div :class="['NFT-wrap', appStore.curDevice == 'pc' ? 'van-hairline--top' : '']">
    <!-- 头像以及个人信息 -->
    <div class="avatar-head-wrap" v-show="appStore.curDevice !== 'pc'">
      <img :src="avatar" alt="" class="avatar" />
      <div class="addr">{{ $t('detail.25') }}</div>
    </div>
    <div class="avatar-wrap" v-if="accStore.avartList.length !== 0">
      <label class="card" v-for="(item, index) in accStore.avartList" :key="index">
        <input
          class="card__input"
          type="checkbox"
          v-model="item.check"
          @change="handleClick(item, index)"
        />
        <div class="card__body">
          <div class="card__body-cover">
            <img class="card__body-cover-image" :src="item.uri" />
            <span class="card__body-cover-chackbox">
              <svg class="card__body-cover-chackbox--svg" viewBox="0 0 12 10">
                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
              </svg>
            </span>
          </div>
        </div>
      </label>
    </div>
    <van-empty
      v-else
      class="empty"
      :image="'https://game.legendranch.app/legendaryranch/empty-image-search.png'"
      :description="$t('msg.19')"
    />
    <!-- 按钮 -->
    <footer v-show="accStore.avartList?.length">
      <div class="btn" v-show="false">{{ $t('dialog.7') }}</div>
      <div class="btn" @click="hanldeConfirm">{{ $t('dialog.1') }}1</div>
      <div class="btn" @click="hanldeCancel">{{ $t('dialog.6') }}</div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.NFT-wrap {
  @include -width(100%, 100%, 12.6rem);
  // height: 100vh;
  position: relative;
}

.avatar-head-wrap {
  @include -media($phone, display, flex) {
    @include flexPos(center);
  }
  @include -media($pad, display, flex) {
    @include flexPos(center);
  }
  flex-direction: column;
  @include -margin-left(0, 0, 0.65rem);
  .avatar {
    @include -width(2.33rem, 2.33rem, 1.4rem);
    @include -height(2.33rem, 2.33rem, 1.4rem);
    border-radius: 50%;
    border: solid 0.03rem #fff;
    @include -margin-left(0, 0, 0.2rem);
  }
  .addr {
    margin: 0.23rem 0.2rem 0;
    @include flexPos(flex-start);
    .content {
      margin-right: 0.1rem;
      @include -font-size(0.28rem, 0.18rem, 0.18rem);
    }

    .icon-cpy {
      @include -font-size(0.28rem, 0.18rem, 0.18rem);
      transform: translateY(-0.03rem);
    }
  }
}

.avatar-wrap {
  // height: 80vh !important;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 2rem);
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 0.5rem;

  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0.4rem 0 0 0.2rem;
  @include -margin-bottom(1.5rem, 1.5rem, 0.8rem);
  @include -media($phone, padding, 0.4rem 0 0 0.1rem);
  @include -media($phone, grid-gap, 0.3rem);
  @include -media($phone, grid-template-columns, repeat(auto-fit, 2rem));
}

.empty {
  width: 100%;
  height: 80vh;
  @include flexPos(center);
  flex-direction: column;
}

:deep(.van-empty__image) {
  width: 4.5rem !important;
  height: 4.5rem !important;
  margin: 0 auto;
  // margin-left: 45%;
  @include flexPos(center);
  flex-direction: column;
  img {
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.8;
  }
}

footer {
  width: 100%;
  @include flexPos(space-around, center);
  padding-bottom: 0.2rem;
  .btn {
    @include flexPos(center);
    @include -width(2.4rem, 2.4rem, 1.5rem);
    @include -height(0.7rem, 0.7rem, 0.5rem);
    @include -font-size(0.24rem, 0.18rem, 0.18rem);
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
  }
  .back-btn {
    width: 100%;
    height: 0.7rem;
    @include flexPos(center);
    font-size: 0.28rem;
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 6px;
    position: absolute;
    // bottom: 0.5rem;
    left: 0;
    cursor: pointer;
  }
}

.label-wrap {
  width: 100%;
  @include flexPos(space-between, center);
  font-size: 0.24rem;
  margin-top: 0.14rem;
  .num-wrap {
    @include flexPos(center);
  }
  .rate {
    color: #ffca81;
    margin-right: 0.2rem;
  }
  .name {
    color: rgba($color: #908b9f, $alpha: 1);
  }
  .val {
    color: #fff;
  }
}

.card {
  // --background: #fff;
  --background: linear-gradient(to bottom, transparent, #402866 100%);
  --background-chackbox: #0082ff;
  --background-image: #fff, rgba(0, 107, 175, 0.2);
  --text-color: #666;
  --text-headline: #000;
  // --card-shadow: #0082ff;
  --card-height: 2rem;
  --card-witght: 2rem;
  --card-radius: 12px;
  --header-height: 0;
  --blend-mode: overlay;
  --transition: 0.15s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.card:nth-child(odd) .card__body-cover-image {
  --x-y1: 100% 90%;
  --x-y2: 67% 83%;
  --x-y3: 33% 90%;
  --x-y4: 0% 85%;
}
.card:nth-child(even) .card__body-cover-image {
  --x-y1: 100% 85%;
  --x-y2: 73% 93%;
  --x-y3: 25% 85%;
  --x-y4: 0% 90%;
}
.card__input {
  position: absolute;
  display: block;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}
.card__input:checked ~ .card__body {
  --shadow: 0 0 0 3px var(--card-shadow);
}
.card__input:checked ~ .card__body .card__body-cover-chackbox {
  --chack-bg: var(--background-chackbox);
  --chack-border: #fff;
  --chack-scale: 1;
  --chack-opacity: 1;
}
.card__input:checked ~ .card__body .card__body-cover-chackbox--svg {
  --stroke-color: #fff;
  --stroke-dashoffset: 0;
}
.card__input:checked ~ .card__body .card__body-cover:after {
  --opacity-bg: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
}
.card__input:checked ~ .card__body .card__body-cover-image {
  --filter-bg: grayscale(0);
}
.card__input:disabled ~ .card__body {
  cursor: not-allowed;
  opacity: 0.5;
}
.card__input:disabled ~ .card__body:active {
  --scale: 1;
}
.card__body {
  display: grid;
  grid-auto-rows: calc(var(--card-height) - var(--header-height)) auto;
  background: var(--background);
  height: var(--card-height);
  width: var(--card-witght);
  border-radius: var(--card-radius);
  overflow: hidden;
  position: relative;
  cursor: pointer;
  backdrop-filter: blur(20px);
  border: 1px solid rgba($color: #fff, $alpha: 0.5);
  box-shadow: var(--shadow, 0 4px 4px 0 rgba(0, 0, 0, 0.02));
  -webkit-transition: box-shadow var(--transition), -webkit-transform var(--transition);
  transition: box-shadow var(--transition), -webkit-transform var(--transition);
  transition: transform var(--transition), box-shadow var(--transition);
  transition: transform var(--transition), box-shadow var(--transition),
    -webkit-transform var(--transition);
  -webkit-transform: scale(var(--scale, 1)) translateZ(0);
  transform: scale(var(--scale, 1)) translateZ(0);
}
.card__body:active {
  --scale: 0.96;
}
.card__body-cover {
  --c-border: var(--card-radius) var(--card-radius) 0 0;
  --c-width: 100%;
  --c-height: 100%;
  position: relative;
  overflow: hidden;
  @include flexPos(center, flex-end);
}
.card__body-cover:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: var(--c-width);
  height: var(--c-height);
  border-radius: var(--c-border);
  // background: -webkit-gradient(linear, left top, right bottom, from(var(--background-image)));
  // background: linear-gradient(to bottom right, var(--background-image));
  // mix-blend-mode: var(--blend-mode);
  // opacity: var(--opacity-bg, 1);
  -webkit-transition: opacity var(--transition) linear;
  transition: opacity var(--transition) linear;
}
.card__body-cover-image {
  width: 1.67rem;
  height: 1.67rem;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: var(--c-border);
  // -webkit-filter: var(--filter-bg, grayscale(1));
  // filter: var(--filter-bg, grayscale(1));
  // -webkit-clip-path: polygon(
  //   0% 0%,
  //   100% 0%,
  //   var(--x-y1, 100% 90%),
  //   var(--x-y2, 67% 83%),
  //   var(--x-y3, 33% 90%),
  //   var(--x-y4, 0% 85%)
  // );
  // clip-path: polygon(
  //   0% 0%,
  //   100% 0%,
  //   var(--x-y1, 100% 90%),
  //   var(--x-y2, 67% 83%),
  //   var(--x-y3, 33% 90%),
  //   var(--x-y4, 0% 85%)
  // );
}
.card__body-cover-chackbox {
  // background: var(--chack-bg, var(--background-chackbox));
  // border: 2px solid var(--chack-border, #fff);
  position: absolute;
  // right: 10px;
  // top: 10px;
  right: 1rem;
  top: 0.6rem;
  z-index: 1;
  width: 0.35rem;
  height: 0.35rem;
  border-radius: 50%;
  opacity: var(--chack-opacity, 0);
  transition: transform var(--transition), opacity calc(var(--transition) * 1.2) linear,
    -webkit-transform var(--transition) ease;
  -webkit-transform: scale(var(--chack-scale, 0));
  transform: scale(var(--chack-scale, 0));
}
.card__body-cover-chackbox--svg {
  width: 0.8rem;
  height: 0.6rem;
  display: inline-block;
  vertical-align: top;
  fill: none;
  margin: 8px 0 0 3px;
  stroke: var(--stroke-color, #fff);
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: var(--stroke-dashoffset, 16px);
  -webkit-transition: stroke-dashoffset 0.4s ease var(--transition);
  transition: stroke-dashoffset 0.4s ease var(--transition);
}
.card__body-header {
  height: var(--header-height);
  background: var(--background);
  padding: 0 0.2rem 0.2rem 0.2rem;
}
.card__body-header-title {
  color: var(--text-headline);
  font-weight: 700;
  margin-bottom: 0.1rem;
}
.card__body-header-subtitle {
  color: var(--text-color);
  font-weight: 500;
  font-size: 0.24rem;
}
</style>
