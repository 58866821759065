<!-- 账户组件 -->
<script setup>
import { computed, ref, reactive, onMounted } from 'vue';
import $load from '@cps/GlobalLoading';
import { storeToRefs, useAppStore } from '@store/appStore';
import useAccountStore from '@store/accountStore';
import { setShowNavPanel } from '../useCardPackage.js';
import { plusXing, handleCopy } from '@/utils/tools';
import commonObj from '@/contracts/commonContract.js';
import { ElMessage } from 'element-plus';
import i18n from '@/utils/i18n.js';
import { bpGt } from '@/utils/bpMath';
import { TRADE_TYPE} from '@/constants'
const $t = i18n.global.t;
onMounted(() => {
  handleCopy();
});
const accStore = useAccountStore();
const appStore = useAppStore();
const userRewardObj = new commonObj();

const userReward = ref({ tcoin: '', gcoin: '' });
(async () => {
  try {
    const reward = await userRewardObj.getUserCenter();
    userReward.value = { tcoin: reward.bvt, gcoin: reward.bvg };
    // console.log('userReward',userReward.value)
  } catch (error) {
    // console.log('userReward error..', error);
  }
})();
/**
 * 性别列表
 */
const genderList = reactive([
  { id: 1, label: 'panel.13', name: 'male', active: true },
  { id: 2, label: 'panel.14', name: 'female', active: false }
]);

const genderLabel = computed(() => {
  let label = '--';
  if(accStore.gender !==0){
    let genderItem = genderList.find(it => it.id === accStore.gender);
    label = genderItem.label;
  }
  return label;
})

// 基础信息（昵称、星球、胜率）
const basicInfo = computed(() => {
  return [
    { id: 1, label: 'panel.5', name: 'genders', val: genderLabel.value },
    {
      id: 2,
      label: 'panel.6',
      name: 'wallet',
      val:
        appStore.curDevice === 'phone'
          ? !appStore.defaultAccount
            ? '--'
            : plusXing(accStore?.spaceName, 6, 4)
          : !appStore.defaultAccount
          ? '--'
          : accStore?.spaceName
    },
    { id: 3, label: 'panel.7', name: 'person', val: accStore.username || '--' },
    { id: 4, label: 'panel.8', name: 'planet', val: accStore.planetName || '--' }
    // { id: 5, label: 'panel.9', name: 'win', val: '-' }
  ];
});

// 遊戲信息
const rateList = computed(() => {
  return [
    { id: 1, label: 'PVE', name: 'pve', val: '' },
    { id: 2, label: 'PVP', name: 'pvp', val: '' }
  ];
});
const coinList = computed(() => {
  return [
    { id: 1, label: TRADE_TYPE[1], name: 'tcoin', val: userReward.value.tcoin },
    { id: 2, label: TRADE_TYPE[2], name: 'gcoin', val: userReward.value.gcoin }
  ];
});


const gender = computed(() => {
  return genderList.find(it => it.active).id;
});
/**
 * 选择性别
 */
function pickGender(id) {
  genderList.forEach((item) => {
    item.active = false;
    if (id === item.id) {
      item.active = true;
      console.log('gender..', gender.value);
    }
  });
}

/**
 * 开启修改性别
 */
const isEdited = ref(true);
const isSave = ref(false);
const username = ref('');
function handleEdit() {
  isEdited.value = false;
  isSave.value = true;
  basicInfo.value[0].val = '';
}

function handleCancel() {
  isEdited.value = true;
  isSave.value = false;
  basicInfo.value[0].val = genderLabel.value;
}

async function handleSave() {
  $load({ isShow: true });
  try{
    const token = await accStore.verify();
    if(!token) {
      console.log('ererere')
      $load({ isShow: false });
      ElMessage({
        message: $t('msg.12'),
        type: 'error'
      });
      return
    }
    await accStore.editUserGender(gender.value,token);
    isEdited.value = true;
    isSave.value = false;
    // basicInfo.value[0].val = gender.value;
    ElMessage({
      message: $t('msg.11'),
      type: 'success'
    });
  }catch{
    ElMessage({
      message: $t('msg.12'),
      type: 'error'
    });
  }
  $load({ isShow: false });
}
</script>

<template>
  <div class="account-wrap">
    <!-- 标题 Info -->
    <div :class="['title', appStore.curDevice === 'pc' ? 'van-hairline--bottom' : '']">
      {{ $t('panel.4') }}
    </div>
    <!-- 基础信息表单 -->
    <div
      :class="['row-wrap', item.name === 'win' ? '' : 'van-hairline--bottom']"
      v-for="item in basicInfo"
      :key="item.id"
    >
      <!-- 左侧 icon+名称 -->
      <div class="label-wrap">
        <i
          :class="['iconfont', `icon-${item.name}`, item.name === 'planet' ? 'icon-1' : 'icons']"
        ></i>
        <div :class="[item.label == 'panel.9' ? 'long-label' : 'label']">{{ $t(item.label) }}</div>
      </div>
      <!-- 中间内容区域 val 或者可编辑文本 -->
      <div :class="['content-wrap']">
        <!-- 修改性别 -->
        <div class="gender-wrap" v-show="item.name === 'genders' && isSave ">
          <div
            :class="[it.active ? 'active' : '']"
            v-for="(it, idx) in genderList"
            :key="idx"
            @click="pickGender(it.id)"
          >
            {{ $t(it.label) }}
          </div>
        </div>
        <!-- 其他文本内容 -->
        <div class="val">{{ $t(item?.val ?? '') }}</div>
        <!-- 功能区域 控制按钮 -->
        <div class="ctrl-wrap" v-show="appStore.curDevice === 'pc'">
          <!-- 修改性别按钮 -->
          <div class="btn" v-show="item.name === 'genders' && isEdited && accStore.gender === 0" @click="handleEdit">
            {{ $t('dialog.4') }}
          </div>
          <div class="btn-wrap" v-show="item.name === 'genders' && isSave">
            <div class="cancel" @click="handleCancel">{{ $t('dialog.6') }}</div>
            <div class="save" @click="handleSave">{{ $t('dialog.5') }}</div>
          </div>
          <!-- 复制账户地址 -->
          <div
            :data-clipboard-text="appStore.defaultAccount"
            class="iconfont icons icon-cpy cpy-btn"
            v-show="item.name === 'wallet'"
          ></div>
        </div>
      </div>
    </div>

    <!-- 标题 -->
    <div :class="['title', appStore.curDevice === 'pc' ? 'van-hairline--bottom mt-5' : 'mt-9']">
      {{ $t('panel.10') }}
    </div>
    <!-- 游戏数据展示 -->
    <!-- <div class="field-wrap">

      <div class="block-wrap">
        <div class="game-coins icons-winner"></div>
        <div class="label">{{ $t('panel.11') }}</div>
      </div>

      <div class="flex">
        <div class="rate-wrap" v-for="item in rateList" :key="item.id">
          <div class="label">{{ $t(item.label) }}</div>
          <div class="val">-</div>
        </div>
      </div>
    </div> -->
    <div class="field-wrap">
      <!-- 标题 游戏收益 -->
      <div class="block-wrap">
        <div class="game-coins icons-earn"></div>
        <div class="label">{{ $t('panel.12') }}</div>
      </div>
      <!-- 数据 -->
      <div class="flex">
        <div class="rate-wrap" v-for="item in coinList" :key="item.id">
          <div class="label">{{ $t(item.label) }}</div>
          <div class="val">{{ `${bpGt(item.val?.replaceAll(',',''),0) ? `${item.val}` : `0`}` }}</div>
        </div>
      </div>
    </div>

    <footer>
      <div class="btn" v-show="appStore.curDevice !== 'pc' && isSave" @click="handleCancel">
        {{ $t('dialog.6') }}
      </div>
      <div class="btn" v-show="appStore.curDevice !== 'pc' && isEdited && accStore.gender === 0" @click="handleEdit">
        {{ $t('dialog.4') }}
      </div>
      <div
        class="btn"
        v-show="appStore.curDevice !== 'pc' && isEdited "
        @click="setShowNavPanel(true)"
      >
        {{ $t('dialog.3') }}
      </div>
      <div class="btn" v-show="appStore.curDevice !== 'pc' && isSave"  @click="handleSave">
        {{ $t('dialog.5') }}
      </div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.account-wrap {
  @include flexPos(flex-start, flex-start);
  flex-direction: column;
  @include -font-size(0.3rem, 0.22rem, 0.22rem);
  @include -height(100vh, 100%, 100%);
}

.icons {
  font-size: 0.3rem;
  cursor: pointer;
}

.icon-1 {
  font-size: 0.28rem;
  cursor: pointer;
}

.game-coins {
  @include -width(0.4rem, 0.4rem, 0.4rem);
  @include -height(0.3rem, 0.3rem, 0.3rem);
}

.mt-5 {
  margin-top: 0.5rem !important;
}

.mt-9 {
  margin-top: 0.9rem !important;
}

.title {
  display: flex;
  align-items: flex-start;
  width: 100%;
  @include -font-size(0.6rem, 0.4rem, 0.4rem);
  // font-family: 'pingfang-semibold';
  line-height: 0.7rem;
  font-weight: 600;
  color: #fff;
  @include -media($pc, line-height, 0.46rem);
  @include -padding-bottom(0.25rem, 0.2rem, 0.2rem);
  @include -margin-top(0.4rem, 0.4rem, 0);
}

// 行信息
.row-wrap {
  @include -media($pc, display, flex) {
    @include flexPos(flex-start, center);
  }
  @include flexPos(space-between, center);
  width: 100%;
  color: white;
  padding: 0.38rem 0;
  @include -media($pc, padding, 0.25rem);
  :last-child {
    align-items: flex-end;
  }

  .block-wrap {
    @include flexPos(center);
    height: 0.5rem;
    padding-right: 0.2rem;
    @include -media($pc, border-right, 1px solid rgba(255, 255, 255, 0.5)); // pc 端有右侧边框
    .label {
      @include -font-size(0.28rem, 0.28rem, 0.18rem);
      @include -media($phone, line-height, 0.33rem);
      @include -margin-left(0.1rem, 0.15rem, 0.15rem);
    }
  }

  //表头 icon + label-name
  .label-wrap {
    @include flexPos(flex-start, center);
    flex-wrap: nowrap;
    min-width: 1.35rem;
    @include -media($pc, border-right, 1px solid rgba(255, 255, 255, 0.5)); // pc 端有右侧边框
    .label {
      min-width: 0.3rem;
      padding-right: 0.2rem;
      @include -font-size(0.28rem, 0.22rem, 0.22rem);
      @include -margin-left(0.1rem, 0.15rem, 0.15rem);
    }
  }
  .long-label {
    min-width: 2.5rem !important;
    padding-right: 0.2rem;
    @include -font-size(0.28rem, 0.22rem, 0.22rem);
    @include -margin-left(0.1rem, 0.15rem, 0.15rem);
  }
  // 内容区域
  .content-wrap {
    @include -media($pc, width, 100%);
    @include flexPos(space-between, center);
    padding-left: 0.2rem;
    .gender-wrap {
      // width: 30%;

      @include flexPos(space-between, center);
      @include -font-size(0.28rem, 0.18rem, 0.18rem);
      color: rgba(255, 255, 255, 0.5);
      > div {
        padding-right: 0.25rem;
        cursor: pointer;
      }
      .active {
        color: #fff;
      }
    }
  }
  // 功能按钮
  .ctrl-wrap {
    display: flex;
    .icons {
      font-size: 0.3rem;
    }
    .btn{
      cursor: pointer;
    }
    .btn-wrap {
      @include flexPos(center);
      >div{
        cursor: pointer;
      }
      .save {
        margin-left: 0.2rem;
      }
    }
  }
}
// 游戏数据
.field-wrap {
  @include flexPos(flex-start, flex-start);
  flex-direction: column;
  @include -media($pc, flex-direction, row);
  padding: 0.3rem 0 0 0;
  width: 100%;
  color: #fff;
  line-height: 0.33rem;
  @include -font-size(0.28rem, 0.28rem, 0.22rem);

  .block-wrap {
    @include flexPos(flex-start, center);
    height: 0.2rem;
    min-width: 2rem;
    padding-right: 0.2rem;
    border-right: none;
    .label {
      display: flex;
      @include -font-size(0.3rem, 0.22rem, 0.22rem);
      margin-left: 0.15rem;
    }
  }
  .flex {
    // PC 端横向并排
    display: flex;
    @include -media($pc, justify-content, space-around);
    @include -media($pc, align-items, center);
    // pad phone 对齐开头
    @include flexPos(flex-start, flex-start);
    width: 80%;
    @include -margin-top(0.25rem, 0.25rem, 0);
    // 数据
    .rate-wrap {
      @include flexPos(center);
      @include -margin-right(0.5rem, 0.5rem, 0);
      .val {
        margin-left: 0.3rem;
      }
    }
  }
}

footer {
  width: 100%;
  // position: fixed;
  left: 0;
  bottom: 0.5rem;
  @include flexPos(space-around, center);
  margin: 0.5rem 0;
  .btn {
    @include flexPos(center);
    @include -width(2.4rem, 2.4rem, 0);
    @include -height(0.7rem, 0.7rem, 0);
    @include -font-size(0.28rem, 0.18rem, 0);
    background-color: #5b3bb7;
    color: #fff;
    border-radius: 3px;
  }
}
</style>
